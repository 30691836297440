<template>
  <div>
    <b-tabs v-model="activeTab" @input="getSheetData()">
      <b-tab v-for="x in [1, 2, 3, 4]" :key="x" :title="GenerateTitle(x)" lazy>
        <vue-element-loading :active="loader"></vue-element-loading>
        <b-form-group label="Spreadsheet ID" label-cols="3">
          <b-input v-model="payload.templateSpreadsheetId" trim></b-input>
        </b-form-group>
        <hr />
        <b-table-simple hover small responsive>
          <b-thead>
            <b-tr>
              <b-th>Config</b-th>
              <b-th>Cell</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr>
              <b-td>Order No Cell</b-td>
              <b-td>
                <b-input v-model="payload.orderNoCell" trim></b-input>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>Product Cell</b-td>
              <b-td>
                <b-input v-model="payload.productCell" trim></b-input>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>Client Cell</b-td>
              <b-td>
                <b-input v-model="payload.clientCell" trim></b-input>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>Start Date Cell</b-td>
              <b-td>
                <b-input v-model="payload.startDateCell" trim></b-input>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>Expiry Date Cell</b-td>
              <b-td>
                <b-input v-model="payload.expiryDateCell" trim></b-input>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>Keyword Start Cell</b-td>
              <b-td>
                <b-input v-model="payload.keywordStartCell" trim></b-input>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>Keyword Count Start Cell</b-td>
              <b-td>
                <b-input v-model="payload.keywordCountStartCell" trim></b-input>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>Domain Start Cell</b-td>
              <b-td>
                <b-input v-model="payload.domainStartCell" trim></b-input>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>Anchor Start Cell</b-td>
              <b-td>
                <b-input v-model="payload.anchorStartCell" trim></b-input>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>Publish Date Start Cell</b-td>
              <b-td>
                <b-input v-model="payload.publishDateStartCell" trim></b-input>
              </b-td>
            </b-tr>
            <b-tr>
              <b-td>Target Start Cell</b-td>
              <b-td>
                <b-input v-model="payload.targetStartCell" trim></b-input>
              </b-td>
            </b-tr>
          </b-tbody>
        </b-table-simple>
        <hr />
        <div class="d-flex">
          <b-button variant="success" class="ml-auto" @click="saveSheet" :disabled="loader"> Submit </b-button>
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>

<script>
import api from "@api";
export default {
  name: "SheetTemplate",
  props: {
    agentCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      activeTab: null,
      payload: {
        templateSpreadsheetId: null,
        orderNoCell: null,
        productCell: null,
        clientCell: null,
        startDateCell: null,
        expiryDateCell: null,
        keywordStartCell: null,
        keywordCountStartCell: null,
        domainStartCell: null,
        anchorStartCell: null,
        publishDateStartCell: null,
        targetStartCell: null,
      },
      loader: false,
    };
  },
  async mounted() {},
  methods: {
    GenerateTitle(x) {
      switch (x) {
        case 1:
          return "BlogRoll";
        case 2:
          return "BlogPost";
        case 3:
          return "Diversity";
        case 4:
          return "GuestPost";
      }
    },
    async getSheetData() {
      this.loader = true;
      let type = this.activeTab + 1;
      try {
        const response = await api.get(`agent/sheet/${this.agentCode}/${type}`);
        this.payload = response;
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
    async saveSheet() {
      this.loader = true;

      try {
        let type = this.activeTab + 1;
        await api.put(`agent/sheet/${this.agentCode}/${type}`, this.payload);
        this.$emit(`onSaveSuccess`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader = false;
      }
    },
  },
};
</script>
