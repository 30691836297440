<template>
  <b-form @submit.prevent="save">
    <vue-element-loading :active="loader.form" />
    <b-form-group label="Agent Code *">
      <b-form-input v-model="form.code" trim />
    </b-form-group>

    <b-form-group label="Agent Name *">
      <b-form-input v-model="form.description" trim />
    </b-form-group>

    <b-form-group label="Image URL *">
      <b-form-input v-model="form.imageUrl" trim />
    </b-form-group>

    <div class="text-right">
      <b-button variant="success" class="mr-1" @click.stop="save">
        <feather-icon icon="SaveIcon"></feather-icon>
        Save
      </b-button>
      <cancel-button @onClick="cancel"></cancel-button>
    </div>
  </b-form>
</template>

<script>
import api from "@api";

export default {
  name: "AgentDetail",
  props: {
    agentCode: { type: String, default: null },
  },
  data() {
    return {
      form: {
        code: null,
        description: null,
        imageUrl: null,
      },

      loader: {
        form: false,
      },
    };
  },

  async mounted() {
    if (this.agentCode) await this.loadAgent();
  },

  methods: {
    async loadAgent() {
      this.loader.form = true;
      try {
        let result = await api.get(`agent/${this.agentCode}`);
        this.form = result;
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.form = false;
      }
    },
    async save() {
      this.loader.form = true;
      try {
        if (this.agentCode) await api.put(`agent/${this.agentCode}`, this.form);
        else await api.post(`agent`, this.form);
        this.clearObject(this.form);
        this.$emit(`onSaveSuccess`);
        this.showToast(`success`, `Save Success`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.form = false;
      }
    },

    cancel() {
      this.$emit(`onCancel`);
    },
  },
};
</script>
