<template>
  <div>
    <b-row>
      <b-col cols="8">
        <b-card>
          <vue-element-loading :active="loader.table"></vue-element-loading>
          <b-table :items="table.items" :fields="table.fields" small striped fixed>
            <template #cell(productType)="data">
              <div>
                <span>{{ data.item.productTypeName }}</span>
                <small v-if="data.item.productTypeCode == 1" class="text-warning ml-1">
                  {{ data.item.blogRollCount | number }}
                </small>
                <small v-if="data.item.productTypeCode == 5" class="text-warning ml-1">
                  {{ data.item.blogPostCount | number }}
                </small>
              </div>
              <div v-if="data.item.productTypeCode == 1">
                <span>Validity : {{ data.item.validityPeriod }} Days</span>
              </div>
            </template>
            <template #cell(price)="data">
              <div v-if="data.item.basePrice > 0" class="d-flex">
                <span>Base</span>
                <span class="ml-auto text-warning">{{ data.item.basePriceCur }} {{ data.item.basePrice | number }}</span>
              </div>
              <div class="d-flex">
                <span>Sell</span>
                <span class="ml-auto text-success">{{ data.item.sellPriceCur }} {{ data.item.sellPrice | number }}</span>
              </div>
            </template>
            <template #cell(action)="data">
              <b-button variant="warning" size="sm" @click.stop="edit(data.item.code)">
                <b-icon icon="pen"></b-icon>
              </b-button>
              <b-button variant="danger" size="sm" class="ml-1" @click.stop="remove(data.item.code)">
                <b-icon icon="trash"></b-icon>
              </b-button>
            </template>
          </b-table>
        </b-card>
      </b-col>
      <b-col>
        <vue-element-loading :active="loader.form"></vue-element-loading>
        <b-card>
          <b-form-group label="Product Type">
            <v-select v-model="payload.productTypeCode" :options="parameter.productTypeList" :reduce="x => x.code" label="description"> </v-select>
          </b-form-group>
          <b-form-group label="Description">
            <b-input v-model="payload.description"></b-input>
          </b-form-group>
          <b-form-group v-if="payload.productTypeCode == 1" label="Blog Roll Count">
            <vue-numeric v-model="payload.blogRollCount" separator="." class="form-control"></vue-numeric>
          </b-form-group>
          <b-form-group v-if="payload.productTypeCode == 5" label="Blog Post Count">
            <vue-numeric v-model="payload.blogPostCount" separator="." class="form-control"></vue-numeric>
          </b-form-group>
          <b-form-group v-if="payload.productTypeCode != 1 && payload.productTypeCode != 5 && payload.productTypeCode != 4" label="Base Price">
            <b-input-group>
              <template #prepend>
                <b-form-select v-model="payload.basePriceCur" :options="parameter.currencyList"></b-form-select>
              </template>
              <vue-numeric v-model="payload.basePrice" :currency="parameter.basePriceCur" separator="." class="form-control"></vue-numeric>
            </b-input-group>
          </b-form-group>
          <b-form-group v-if="payload.productTypeCode != 4" label="Sell Price">
            <b-input-group>
              <template #prepend>
                <b-form-select v-model="payload.sellPriceCur" :options="parameter.currencyList"></b-form-select>
              </template>
              <vue-numeric v-model="payload.sellPrice" :currency="parameter.sellPriceCur" separator="." class="form-control"></vue-numeric>
            </b-input-group>
          </b-form-group>
          <b-form-group v-if="payload.productTypeCode == 1" label="Validity Period">
            <b-input v-model="payload.validityPeriod"></b-input>
          </b-form-group>
          <template #footer>
            <b-row>
              <b-col cols="5">
                <b-button variant="secondary" block @click.stop="cancel">Cancel</b-button>
              </b-col>
              <b-col cols="7">
                <b-button variant="success" block @click.stop="save">
                  {{ editMode ? `Save Changes` : `Add Product` }}
                </b-button>
              </b-col>
            </b-row>
          </template>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import api from "@api";

export default {
  name: `AgentProduct`,
  props: {
    agentCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      editMode: false,
      parameter: {
        productTypeList: [],
        currencyList: [
          { value: `IDR`, text: `IDR` },
          { value: `USDT`, text: `USDT` },
        ],
      },
      payload: {
        description: null,
        productTypeCode: null,
        blogRollCount: 0,
        basePrice: 0,
        sellPrice: 0,
        validityPeriod: 0,
        basePriceCur: null,
        sellPriceCur: null,
      },
      selectedProductCode: null,
      table: {
        items: [],
        fields: [
          { key: `description`, label: `Description` },
          { key: `productType`, label: `Type` },
          { key: `price`, label: `Price` },
          { key: `action`, label: ``, class: "text-right" },
        ],
      },
      loader: { table: false, form: false },
    };
  },
  async mounted() {
    this.loadProductType();
    await this.loadProduct();
  },
  methods: {
    async loadProduct() {
      this.loader.table = true;
      try {
        this.table.items = await api.get(`product/agentproduct`, { agentCode: this.agentCode });
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.table = false;
      }
    },
    async loadProductType() {
      this.parameter.productTypeList = await api.get(`product/productType`);
    },
    cancel() {
      this.clearObject(this.payload);
      this.editMode = false;
    },
    async edit(code) {
      this.loader.form = true;
      this.editMode = true;
      this.selectedProductCode = code;

      try {
        this.payload = await api.get(`product/agentproduct/${code}`);
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.form = false;
      }
    },
    async save() {
      this.loader.form = true;
      try {
        if (this.editMode) {
          await api.put(`product/agentproduct/${this.selectedProductCode}`, this.payload);
        } else {
          await api.post(`product/agentproduct/${this.agentCode}`, this.payload);
        }
        await this.loadProduct();
        this.cancel();
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.form = false;
      }
    },
    async remove(code) {
      try {
        await api.delete(`product/agentproduct/${code}`);
        this.loadProduct();
      } catch (error) {
        this.showToast(`error`, error);
      }
    },
  },
};
</script>
