<template>
  <div>
    <vue-element-loading :active="loader"></vue-element-loading>
    <b-list-group>
      <b-list-group-item v-for="x in userList" :key="x.code" class="d-flex justify-content-between align-items-center">
        {{ x.username }}
        <b-badge variant="primary">{{ x.agentRoleDesc }}</b-badge>
      </b-list-group-item>
    </b-list-group>
  </div>
</template>

<script>
import api from "@api";

export default {
  name: "AgentUser",
  props: {
    agentCode: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      userList: [],
      loader: false,
    };
  },
  async mounted() {
    this.getUserList();
  },
  methods: {
    async getUserList() {
      const response = await api.get(`useraccount/agent/${this.agentCode}`);
      this.userList = response;
    },
  },
};
</script>
