<template>
  <div class="component-wrapper">
    <b-card-group columns>
      <vue-element-loading :active="loader.table" text="Loading Agent.."></vue-element-loading>
      <b-card v-for="x in table.items" :key="x.code">
        <template #header>
          <h4>( {{ x.code }} ) {{ x.description }}</h4>
          <b-button class="ml-auto" variant="warning" @click.stop="showDetail(x.code)">Edit</b-button>
          <b-button class="ml-1" variant="info" @click.stop="showTemplate(x.code)">
            <span>Sheet Template</span>
          </b-button>
        </template>

        <b-img :src="x.imageUrl ? x.imageUrl : defaultImage" fluid></b-img>

        <template #footer>
          <b-row>
            <b-col>
              <b-button block variant="primary" @click.stop="showProduct(x.code)">
                <span>{{ x.productCount }} </span>
                <span>Products</span>
              </b-button>
            </b-col>

            <b-col>
              <b-button block variant="success" @click.stop="showUser(x.code)">
                <span>{{ x.userCount }} </span>
                <span>Users</span>
              </b-button>
            </b-col>
          </b-row>
        </template>
      </b-card>
      <b-card>
        <b-button variant="primary" size="lg" block @click.stop="showDetail()">
          <b-icon icon="plus" scale="2"></b-icon>
          New Agent
        </b-button>
      </b-card>
    </b-card-group>

    <b-modal v-model="modal.detail" :title="selectedAgentCode ? `Edit Agent` : `Create Agent`" hide-footer centered lazy no-close-on-backdrop @hide="selectedAgentCode = null">
      <agent-detail :agentCode="selectedAgentCode" @onSaveSuccess="saveSuccess" @onCancel="modal.detail = false"></agent-detail>
    </b-modal>

    <b-modal v-model="modal.product" title="Agent Product" hide-footer centered lazy size="xl" @hide="selectedAgentCode = null">
      <agent-product v-if="selectedAgentCode" :agentCode="selectedAgentCode"></agent-product>
    </b-modal>

    <b-modal v-model="modal.user" title="Agent User" hide-footer centered lazy @hide="selectedAgentCode = null">
      <agent-user v-if="selectedAgentCode" :agentCode="selectedAgentCode"></agent-user>
    </b-modal>

    <b-modal v-model="modal.sheet" :title="`Sheet Template ${selectedAgentCode}`" size="xl" hide-footer centered lazy @hide="selectedAgentCode = null">
      <sheet-template v-if="selectedAgentCode" :agentCode="selectedAgentCode" @onSaveSuccess="saveTemplateSuccess"></sheet-template>
    </b-modal>
  </div>
</template>

<script>
import api from "@api";
import AgentDetail from "./Detail.vue";
import AgentProduct from "./AgentProduct.vue";
import AgentUser from "./AgentUser.vue";
import SheetTemplate from "./SheetTemplate.vue";
import defaultImage from "@/assets/images/no-img.jpg";

export default {
  name: "AgentList",
  components: { AgentDetail, AgentProduct, AgentUser, SheetTemplate },
  data() {
    return {
      table: {
        items: [],
        fields: [
          { key: "code", label: "Agent Code" },
          { key: "description", label: "Agent Name" },
          { key: "actions", label: "", class: "text-right" },
        ],
      },
      selectedAgentCode: null,
      loader: { table: false },
      modal: { detail: false, product: false, sheet: false, user: false },
      defaultImage,
    };
  },
  created() {
    this.loadAgents();
  },
  methods: {
    async loadAgents() {
      this.loader.table = true;
      try {
        const response = await api.get("/agent");
        this.table.items = response;
      } catch (error) {
        this.showToast(`error`, error);
      } finally {
        this.loader.table = false;
      }
    },
    showDetail(agentCode = null) {
      this.selectedAgentCode = agentCode;
      this.modal.detail = true;
    },
    showProduct(agentCode) {
      this.selectedAgentCode = agentCode;
      this.modal.product = true;
    },
    showUser(agentCode) {
      this.selectedAgentCode = agentCode;
      this.modal.user = true;
    },
    showTemplate(agentCode) {
      this.selectedAgentCode = agentCode;
      this.modal.sheet = true;
    },
    saveSuccess() {
      this.modal.detail = false;
      this.loadAgents();
    },
    saveTemplateSuccess() {
      this.modal.sheet = false;
    },
  },
};
</script>
